.ConnectionBlock_1 {

}

.ConnectionBlock_1-Container {
    padding: 4rem 0;
    margin: 7rem auto;
    background: rgb(0,0,0);
    background: linear-gradient(45deg, rgba(0,0,0,1) 0%, rgba(209,39,16,1) 50%, rgba(0,0,0,1) 100%);}


.ConnectionBlock_1-Container .ConnectionBlock_1-Text {
    text-align: center;
    margin-bottom: 2rem;
}

.ConnectionBlock_1-Container .ConnectionBlock_1-Button {
    align-items: center;
    text-align: center;
    justify-content: center;
}

.ConnectionBlock_1-Container .ConnectionBlock_1-Button .Button {
    margin: 0 auto;
    /* background-color: #000000; */
    border: 2px solid #000000;
}

.ConnectionBlock_1-Container .ConnectionBlock_1-Button .Button:hover {
    background-color: #000000;    
}
