.Carousel{
    width: 100%;
    overflow: hidden;
    margin: 1rem 0;
}

.Carousel .owl-carousel .item {
    width: 95%;
    margin: auto;
}

.Carousel-Item {
    background-color: var(--color-bg-5);
    align-items: center;
    align-content: center;
    text-align: center;
    display: block;
    width: fit-content;
    padding: 2rem;
    margin: 0 2.5rem;
}

.Carousel-Item .row {
    position: relative;
    margin: auto;
}

.Carousel-Item-Text {
    width: 100%;
    font-weight: 500;
    font-style: italic;
    padding: 0 0 2rem 0;
    color: var(--color-text-1);
    text-align: center;
    align-items: center;
    align-content: center;
}

.Carousel-Item-Text h4 {
    font-size: 25px;
    font-weight: 500;
    margin: 0 auto;
}

.Carousel-Item-Person {
    margin: auto 0;
    min-height: 100%;
    align-items: center;
    align-content: center;
    vertical-align: middle !important;
    height: 100% !important;
    display: table-cell;
    color: var(--color-text-1);
    padding: 0 !important;
}

.Carousel-Item-Name {
    width: 100%;
    float: right;
    text-align: right;
    font-weight: 600;
    font-style: italic;
    padding-top: 0.25rem;

}
.Carousel-Item-Name p {
    float: right;
    margin: auto 0;
}

.Carousel-Item-Logo {
    align-items: center;
    text-align: center;
    align-content: center;
    padding: 0 !important;
}

.Carousel-Item-Logo img {
    height: auto;
    margin: 0 auto;
    /* filter: grayscale(100%); */
    transition: all 0.5s ease;
}

.item .Carousel-Item:hover.Carousel-Item-Logo img {
    filter: grayscale(0%);
}

@media (max-width: 992px) {
    .Carousel-Item-Text h4 {
        font-size: 20px;
    }

    .Carousel-Item-Person {
        font-size: 15px;
    }
}