.ContactForm {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto 6rem auto;
    width: 100%;
    overflow: auto;
    padding: 0;
    box-sizing: border-box;
    padding: 10rem 0;
    position: relative;
    background: rgb(0,0,0);
    background: linear-gradient(45deg, rgba(0,0,0,1) 0%, rgba(209,39,16,1) 50%, rgba(0,0,0,1) 100%);
}


.ContactForm-Container {
    margin: 0 auto;
    overflow: visible;
    max-width: 800px;
    padding: 0 1rem;
}

.ContactForm-Container label {
    font-weight: 600;
    margin-top: 1rem;
}

.ContactForm-Container .Contact-Field {
    border: none;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
    padding: 0.5rem;
    font-weight: 400;
}

.ContactForm-Container textarea {
    resize: vertical;
    height: 10rem;
}

.ContactForm-Container .Contact-Button {
    margin: 0 auto;
    margin-top: 1rem;
    padding: 0 2rem;
    border: none;
    background-color: #000;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    width: fit-content;
}

.Contact-Button h6 {
    font-weight: 600;
    padding: 0.5rem 0;
}


.ContactForm-Container .Contact-Field:focus {
    outline: none;
}


