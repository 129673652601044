:root {
    --color-main: #D12710;
    --color-bg: #000000;
    --color-bg-2: #222222;
    --color-bg-3: #b4b4b4;
    --color-bg-4: #000000;
    --color-bg-5: #ffffff;
    --color-text-1: #000000;
    --color-text-2: #ffffff;

    --max-hor-width: 1200px;

    --header-height: 100px;
}

@media screen and (max-width: 992px) {
    :root {
        --header-height: 70px;
    }
}

* {
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Roboto', sans-serif;
    background-color: #000000;
    color: #ffffff;
}

a {
    text-decoration: none;
    color: var(--color-text-2)
}

a:hover {
    color: var(--color-text-2);
}

.row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    max-width: var(--max-hor-width);
}

h1, h2, h3 {
    font-weight: 800;
    margin: 0;
    padding: 0;
}

h4, h5, h6, p {
    font-weight: 400;
    margin: 0;
    padding: 0;
}

.bold {
    font-weight: 800 !important;
}

.row {
    width: 100% !important;
}

.skew {
    transform: skewX(-15deg) !important;
    overflow: hidden;
}

.skew > * {
    transform: skewX(15deg) !important;
}

.skewReverse {
    transform: skewY(-5deg) !important;
    overflow: hidden;
}

.skewReverse > *{
    transform: skewY(5deg) !important;
    overflow: hidden;
}


.roundedCorners {
    border-radius: 8px;
}

.follow {
    width: 100% !important;
    height: 100% !important;
}