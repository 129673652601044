.LandingPage3 {
    position: relative;
    width: 100%;
    height: 50vh;
}

.LandingPage3-Background {
    position: absolute;
    /* top: var(--header-height); */
    top : 0;
    /* margin-bottom: var(--header-height); */
    left: 0;
    width: 100%;
    height: 100%;
}

.LandingPage3-Background img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.LandingPage3-Overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 80%); 
}

.LandingPage3-Container {
    position: relative;
    background: none;
    height: 100%;
    width: 100%;
    max-width: var(--max-hor-width);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.LandingPage3-Title {
    font-size: 3rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: -9rem;
}