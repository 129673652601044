
.Footer_2 {
    width: 100%;
    margin: 0;
    padding: 1rem 0;
    background-color: var(--color-bg-2);
    align-items: center;
    align-content: center;
}
.Footer_2-Main {
    position: relative;
    width: 100%;
    max-width: var(--max-hor-width);
    margin: 0 auto !important;
}
.Footer_2-Sub {
    position: relative;
    width: 100%;
    max-width: var(--max-hor-width);
    margin: 0 auto !important;
    text-align: center;
    padding: 1rem 0;
}

