.FigureBlock_1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    padding: 50px 0;
    background-color:   var(--color-bg-4);
}


.FigureBlock_1-Title {
    margin-bottom: 2rem;
    text-align: center;
}

.FigureBlock_1-Content {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
}

.FigureBlock_1-Content .row {
    width: 100% !important;
}

.FigureBlock_1-Content .row .FigureBlock_1-Item-Col {
    padding: 0 2rem;
    margin: 1rem 0;
    vertical-align: middle !important;
    height: 100%;
    display: table-cell;
}

.FigureBlock_1-Content .row .FigureBlock_1-Item-Container {
    margin: 0 1rem;
    background: rgb(0,0,0);
    background: linear-gradient(45deg, rgba(0,0,0,1) -50%, rgba(209,39,16,1) 50%, rgba(0,0,0,1) 150%);
    text-align: center;
    padding: 1.5rem 0;
}

.FigureBlock_1-Content .row .FigureBlock_1-Item-Container .FigureBlock_1-Item-Container-Number {
    font-size: 7rem;
    font-weight: 800;
}

.FigureBlock_1-Content .row .FigureBlock_1-Item-Container .FigureBlock_1-Item-Container-Text {
    margin-top: 1rem;
    width: 75%;
    margin: 0 auto;
}

.FigureBlock_1-Content .row .FigureBlock_1-Item-Container .FigureBlock_1-Item-Container-Text h4{
    font-size: 1.5rem;
    font-weight: 600;
}