.Altering {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    padding: 50px 0;
    background-color:   var(--color-bg-4);
}

.Altering-Title {
    margin-bottom: 2rem;
    text-align: center;
}

.Altering-Row {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
}

.Altering-Row-Item-Container {
    display: inline-flex;
}

.Altering-Row-Item-Container .Altering-Row-Item-Number {
    background-color: var(--color-main);
    width: fit-content;
    height: fit-content;
    padding: 0 0.85rem;
    margin: 0;
    text-align: center;
    font-size: 35px;
    font-weight: 600;
    border-radius: 50%;
}

.Altering-Row-Item-Col {
    margin: 1rem auto;
}

.Altering-Row-Item-Col-Text {
    padding: 0 1rem;
    vertical-align: middle !important;
    height: 100%;
    display: table-cell;
    margin: 1rem 0;
}

.Altering-Row-Item-Col-Text li {
    margin: 0.75rem 0;
}

.Altering-Row-Item-Col-Image {
    width: 85%;
    margin: 0 auto;
    position: relative;
    align-items: center;
    align-content: center;
    height: 15rem;
}

.Altering-Row-Item-Col-Image-Inner {
    width: 100%;
    position: relative;
    margin: 0 auto ;
    height: 100%;
}

.Altering-Row-Item-Col-Image img {
    width: 120%;
    height: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
}

.Altering-Row-Item-Col-Content ul{
    list-style: none;
    padding: 0;
}

@media screen and (max-width: 768px) {
    .Altering .row {
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .Altering .row .Altering-Row-Item-Col {
        width: 100%;
        margin: 0 auto;
    }
}