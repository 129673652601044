.LandingPage {
    position: relative;
    width: 100%;
    height: 100vh;
}

.LandingPage-Background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.LandingPage-Background img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.LandingPage-Overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.LandingPage-Container {
    position: relative;
    background: none;
    height: 100%;
    width: 100%;
    max-width: var(--max-hor-width);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.LandingPage-Title {
    font-size: 3rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 1rem;
}