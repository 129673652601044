.Button {
    margin: 0 auto;
    color: var(--color-main);
    width: fit-content;
    border-radius: 0.5rem;
    border: 2px solid var(--color-main);
    transition: all 0.2s ease-in-out;
    height: fit-content;
}

.Button a {
    padding: 2rem !important;
    /* transition: all 0.2s ease-in-out; */
}

.Button h6 {
    font-weight: 600;
    height: fit-content;
    padding: 0.5rem 0;
}

.Button:hover{
    background-color: var(--color-main);
    color: var(--color-text-2) !important;
    cursor: pointer;
}

