.Menu{
    position: relative;
    display: inline-flex;
    height: 100%;
    width: auto;
    float: right;
}

.Menu > * {
    height: 100%;
    width: auto;
    padding: 0 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Menu-Toggle {
    display: none;
}

.Menu-Item {
    padding: 0 0.8rem;
}

.Menu-Item a {
    text-decoration: none;
    color: var(--color-text-2);
    font-weight: 600;
}

.Menu-Item a h6 {
    font-weight: 600;
}

.Menu-Item a:hover {
    color: var(--color-text-2);
}

@media screen and (max-width: 992px){
    .Menu {
        position: absolute;
        display: block;
        margin: 0;
        padding: 0;
        width: 100%;
    }

    .Menu-Items {
        display: block;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100vh;
        left: 0;
        top: 0;
        transform: translateX(100%);
        transition: all 0.7s ease-in-out;
        background-color: var(--color-main);
        text-align: center;
        align-items: center;
        align-content: center;
        padding-top: 2.5rem;
    }
    .Menu-Item {
        margin: 2.5rem 0;
    }

    .Menu-Item h6 {
        font-size: 2rem;
    }

    .Menu-Toggle {
        display: flex;
        position: absolute;
        right: 0;
        top: 0;
        height: auto;
        width: auto;
        margin: 1rem 1.5rem;
        z-index: 2;
        cursor: pointer;
    }

    .Menu-Toggle-Bars {
        height: 2rem;
        width: auto
    }

    .Menu-Items-Active {
        transform: translateX(0%) !important;
        z-index: 2;
    }
}