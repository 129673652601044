.MapsContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    overflow: auto;
    padding: 0;
    box-sizing: border-box;
    padding: 50px 0;
}

.MapsContainer-Title {
    margin: 0.5rem 0;
    text-align: center;
}

.MapsContainer-Title > * {
    margin: 1rem 0.5rem;
}

.MapsContainer-Content-Container {
    margin: 1rem 0;
}

.MapsContainer-Content {
    width: 100%;
    margin: 1rem 0;
    object-fit: cover;
    overflow: hidden;
}

.MapsContainer-Content img {
    width: 100%;
    height: 400px;
    object-fit: cover;
}