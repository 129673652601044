.TwoBlocks {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    overflow: auto;
    padding: 0;
    box-sizing: border-box;
}

.TwoBlocks-Block {
    margin-top: 5rem;
}

.TwoBlocks-Block-Content-Container {
    margin-top: 0.5rem;
}

.Content-Column1 {
    margin: 0.25rem 0;
}
.Content-Column1 p{
    font-weight: 800 !important;
}

.TwoBlocks-Block-Title h4 {
    font-weight: 800;
    margin: 0;
    padding: 0;
}