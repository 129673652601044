.LandingPage2 {
    position: relative;
    width: 100%;
    height: 100vh;
}

.LandingPage2-Background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.LandingPage2-Background img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left;
    /* object-fit: contain; */
}

.LandingPage2-Overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
}

.LandingPage2-Container {
    position: relative;
    background: none;
    height: 100%;
    width: 100%;
    max-width: var(--max-hor-width);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.LandingPage2 .col {
    display: block;
    position: relative;
    height: 100%;
}

.LandingPage2-Container .Button {
    float: left;
    margin: 1rem 0;
}

.LandingPage2-Text {
    vertical-align: middle;
    height: 100%;
    display: table-cell;
}

.LandingPage2-Text h1 {
    font-size: 4.5rem;
    font-weight: 800;
}

.LandingPage2-Image-Col {
    position: relative;
    margin: 0 auto;
    height: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

}

.LandingPage2-Double {
    position: absolute;
    z-index: 0;
    width: 90%;
    height: 100%;
    height: 20rem;
    background-color: var(--color-main);
}

.LandingPage2-Image {
    width: 90%;
    margin: 0 auto;
    position: absolute;
    height: 20rem;
    left: -2.3rem;
    top: 2rem;
    z-index: 1;
    overflow: hidden;
    object-fit: cover;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.LandingPage2-Image-Inner {
    width: 100%;
    position: absolute;
    height: 100%;
}

.LandingPage2-Image img {
    height: auto;
    min-height: 100%;
    width: 120%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
}

@media screen and (max-width: 992px) {
    .LandingPage2-Container {
        padding: 0 1rem;
    }

    .LandingPage2-Image-Col {
        align-items: center;
        align-content: center;
        height: 15rem;
        margin-bottom: 3rem;
        display: none;
    }

    .LandingPage2-Image,
    .LandingPage2-Double {
        height: 15rem;
        display: none;
    }

    .LandingPage2-Text-Col {
        width: 75%
    }

    .LandingPage2-Text h1 {
        font-size: 3rem;
    }
}

@media screen and (max-width: 768px) {
    .LandingPage2-Image-Col,
    .LandingPage2-Image,
    .LandingPage2-Double {
        height: 12rem;
        display: none;
    }

    .LandingPage2-Image {
        top: 1rem;
        left: -1.5rem; 
    }

    .LandingPage2-Image img {
        transform: translate(-50%, -50%);
    }


}