.BoxArray {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  overflow: auto;
  box-sizing: border-box;
  padding: 10rem 0;
  background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,1) 100%), url('../../../Images/SITE-1.png'); 

}

.BoxArray-Title {
    width: 100%;
    text-align: center;
}

.BoxArray .col {
    padding: 0 2.5rem;
}

.Box {
    width: 100%;
    height: 15rem;
    position: relative;
    overflow: hidden;
    margin: 3rem 0;
}

.Box .Box-Image {
    width: 100%;
    position: relative;
    height: 100%;
}

.Box .Box-Image img {
    width: 120%;
    height: auto;
    overflow: hidden;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.Box .Box-Image-Overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    background: rgb(209,39,16);
    background: linear-gradient(0deg, rgba(209,39,16,1) 0%, rgba(0,0,0,0) 100%);
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}


.Box .Box-Text {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    text-align: center;
}

.Box .Box-Text .Box-Text-Title,
.Box .Box-Text .Box-Text-Description {
    position: absolute;
    width: 80%;
    top: 50%;
    left: 50%;
    transition: all 0.3s ease-in-out;
}
.Box .Box-Text .Box-Text-Description {
    top: 100%;
}

.Box .Box-Text .Box-Text-Title h3 {
    transform: translate(-50%, -50%);
}

.Box .Box-Text .Box-Text-Description p {
    transform: translate(-50%, 0%);
}

.Box:hover .Box-Text .Box-Text-Title {
    top: 25%;
}

.Box:hover .Box-Text .Box-Text-Description {
    top: 35%;
}

.Box:hover .Box-Image-Overlay {
    background: linear-gradient(0deg, rgba(209,39,16,1) 50%, rgba(0,0,0,0) 100%);;
}