.Team_1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    padding: 50px 0;
    background-color:   var(--color-bg-4);
    background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,1) 100%), url('../../../Images/SITE-1.png'); 
    background-size: cover;
}


.Team_1-Title {
    margin-bottom: 2rem;
    text-align: center;
}

.Team_1-Title .row {
    margin: 0 auto;
}

.Team_1-Content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
}

.Team_1-Content .col {
    text-align: center;
    align-items: center;
}

.Team_1-Content-Member {
    display: flex;
    flex-direction: column;
    width: 25rem;
    max-width: 100%;
    height: auto;
    margin: 2rem auto;
    padding: 0 3rem;
    /* background-color: #ffffff; */
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.Team_1-Content .Team_1-Content-Image,
.Team_1-Content .Team_1-Content-Member-Image {
    width: 12rem;
    height: 12rem;
    overflow: hidden;
    position: relative;
    margin: 0 auto;
}

.Team_1-Content .Team_1-Content-Image {
    width: 100%;
    height: 40rem;
    margin-bottom: 2rem;
}

.Team_1-Content .Team_1-Content-Image-Inner,
.Team_1-Content .Team_1-Content-Member-Image-Inner {
    width: 100%;
    position: relative;
    height: 100%;
}

.Team_1-Content .Team_1-Content-Image-Inner img,
.Team_1-Content .Team_1-Content-Member-Image-Inner img {
    width: 130%;
    height: auto;
    position: absolute;
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%);;
}

.Team_1-Content .Team_1-Content-Image-Inner img{
    width: 100%;
}



.Team_1-Content-Member-Name {
    margin: 1rem 0;
    text-align: center;
}

.Team_1-Content-Member-Name h5 {
    font-weight: 800;
}

.Team_1-Content-Member-SocialMedia {
    margin: 0 auto;
    padding: 1rem 0;
    display: inline-flex;
    justify-content: space-between;
}

.Team-Social-Container {
    width: 100%;
    margin: 0 auto;
    padding: 1rem 0;
    display: inline-flex;
    /* justify-content: space-between; */
}

.Team-Social-Container .Team-Icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 0 0.5rem;
    background-color: var(--color-bg-1);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 768px) {
    .Team_1-Content .Team_1-Content-Image {
        height: 20rem;
    }
}