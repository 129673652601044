:root {
    --padding-logo: 0.5rem;
}

.Header {
    position: fixed;
    z-index: 10;
    background-color: var(--color-bg);
    width: 100%;
    height: var(--header-height);
}

.Header-container {
    height: 100%;
    width: 100%;
    max-width: var(--max-hor-width);
    align-items: center;
    margin: 0 auto;
}

.Logo {
    position: relative;
    height: 100%;
    max-height: 100%;
    width: auto;
    padding: var(--padding-logo);
    float: left;
    margin: 0;
    z-index: 1;
    transition: all 0.8s ease-in-out;
}

.Logo-Active {
    z-index: 0;
}

.Logo img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    float: left !important;
    margin-left: 0 !important;
}

.Menu {
    float: right;
    color: var(--color-text-2);
}