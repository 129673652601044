.Footer {
    width: 100%;
    margin: 3rem 0;
    padding: 5rem 0 1rem 0;
    background-color: var(--color-bg-1);
    align-items: center;
    align-content: center;
}

.Footer-Main {
    background-color: var(--color-bg-2);
    padding: 2rem;
}


.Footer .col {
    padding: 1.5rem;
}

.Footer-Main {
    position: relative;
    width: 100%;
    max-width: var(--max-hor-width);
    margin: 0 auto !important;
}

.Footer-Main-Title {
    margin-bottom: 1rem;
}

.Footer-Main-Content .Footer-Main-Content-Item {
    margin-bottom: 0.7rem;
}

.Footer-Logo-Container {
    width: 100%;
}

.Footer-Main-Content .Footer-Logo-Container img {
    width: 100%;
    height: auto;
    overflow: hidden;
    margin: 0 auto;
}

.Footer-Social-Container {
    width: 100%;
    margin: 0 auto;
    padding: 1rem 0;
    display: inline-flex;
    justify-content: space-evenly;
}

.Footer-Social-Container .Footer-Icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--color-bg-1);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
}