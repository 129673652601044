.Privacy .col {
  padding: 0 1rem;
  align-items: center;
  align-content: center;
  justify-items: center ;
}

.Privacy .container {
    margin: 5rem auto 0 auto;
    align-items: center;
    align-content: center;
    justify-items: center ;
}

.Privacy .row {
    margin: 0 auto;
}

.Privacy h1 {
    text-align: center;
}

.Privacy h2 {
    margin: 2rem 0 0 0;
}

.Privacy p {
    text-align: justify;
    padding: 0.8rem 0;
}

.Privacy table {
    width: 100% ;
    overflow-x: scroll;
    margin: 2rem 0;
}

.Privacy tr td,
.Privacy tr th {
    max-width: 20rem;
    min-width: 5rem;
    padding: 0.5rem 1rem;
    border: 2px solid #ffffff;
}